<template>
  <div class="signature-field mb-2">
    <div class="field-title">
      <v-text-field
        :validation-value="signature"
        :placeholder="$translate('clickToSign')"
        persistent-placeholder
        readonly
        :required="isRequired"
        :class="{ 'is-required': isRequired }"
        :label="$translate(field.name)"
        :rules="validationRules"
        validate-on="submit"
        @click="edit"
      >
        <template #append>
          <div class="actions">
            <v-btn
              v-if="hasSignature"
              color="red"
              :icon="mdiClose"
              variant="flat"
              size="small"
              rounded="rounded"
              @click.stop="clear"
            />
            <v-btn
              color="success"
              :icon="mdiPen"
              variant="flat"
              size="small"
              rounded="rounded"
              @click="showDialog = true"
            />
          </div>
        </template>
      </v-text-field>
    </div>
    <v-expand-transition>
      <v-img
        v-if="hasSignature"
        :src="(signature as string)"
        data-qa="signature-image"
      />
    </v-expand-transition>
  </div>
  <v-dialog
    v-model="showDialog"
    :persistent="true"
    width="auto"
  >
    <v-card>
      <v-card-title>
        <div class="card-title">
          <div class="title">
            {{ $translate(field.name) }}
          </div>
          <v-btn
            size="small"
            variant="flat"
            :icon="mdiClose"
            @click="showDialog = false"
          />
      </div>
      </v-card-title>
      <v-card-text>
        <VueSignaturePad
          ref="signaturePad"
          class="has-dashed-border"
          height="200px"
          :options="{ onEnd: onSign, minDistance: 12 }"
          expanded
        />
        <v-btn
          color="success"
          class="mt-4"
          variant="flat"
          block
          @click="onSubmit"
        >
          {{ $translate('ok') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import{ ref, computed, type PropType } from 'vue';
import { useDynamicValueGetSet } from '@/dynamic-form/composables/use-dynamic-value-get-set';
import type { IVisitFormInputField } from '@einfachgast/shared';
import { VueSignaturePad } from 'vue-signature-pad';
import { mdiPen, mdiClose } from '@mdi/js';
import { useIsRequired } from '@/dynamic-form/composables/use-is-required';
import { useExtractValidationRules } from '@/dynamic-form/composables/use-extract-validation-rules';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  field: {
    type: Object as PropType<IVisitFormInputField>,
      required: true,
      default: () => ({} as IVisitFormInputField),
    },
  },
);
const { t } = useI18n();
const showDialog = ref(false);
const signaturePad = ref<VueSignaturePad | null>(null);
const hasSignature = computed(() => !!signature.value);
const tmpSignature = ref('');
const isRequired = useIsRequired(props.field);
const signature = useDynamicValueGetSet(props.field);

const onSubmit = () => {
  signature.value = tmpSignature.value;
  showDialog.value = false;
};

const clear = () => {
  signature.value = '';
  tmpSignature.value = '';
};

const customValidators = [];
if (isRequired) {
  customValidators.push(
    () => Promise.resolve(hasSignature.value ? true : t('requiredField')),
  );
}
const validationRules = useExtractValidationRules({} as IVisitFormInputField, customValidators);

const edit = () => {
  signature.value = '';
  tmpSignature.value = '';
  showDialog.value = true;
};

const onSign = () => {
  const result = signaturePad.value?.saveSignature();
  if (!result || result?.isEmpty) {
    signature.value = '';
    return;
  }
  tmpSignature.value = result.data as string;
};
</script>
<style lang="scss" scoped>
.field-title,
.card-title {
  display: flex;
  justify-content: space-between;
  .title {
    flex-shrink: 1;
    word-wrap: break-word;
    white-space: wrap;
  }
}
.field-title {
  align-items: center;
  .actions {
      display: flex;
      gap: 4px;
  }
}
</style>
